import { DatePicker, Input, Select, Table, Typography } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { addCommas, config, removeNonNumeric, userInfo } from "../../utils";
import "../Common/Table.css";
import { useWindowSize } from "react-use";

const { Text } = Typography;

const FinanceTable = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterType, setFilterType] = useState("ck");

  const isAdmin = useMemo(() => userInfo?.user.role.includes("admin"), []);

  // fetch finance data
  const {
    data: financeData,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["get-finance-data", startDate, endDate, filterType],
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/statistics/finance?start=${startDate}&end=${endDate}&type=${filterType}`,
        config
      );
      return data?.data;
    },
    {
      enabled: !!startDate && !!endDate && !!filterType,
    }
  );

  const getChildrenClasses = useCallback(() => {
    if (!financeData?.classNames) return;

    let childrenClasses = [];

    financeData?.classNames?.forEach((classObj) => {
      childrenClasses.push({
        title: classObj.class,
        dataIndex: classObj.class,
        width: 160,
      });
    });

    childrenClasses.push({
      title: "Tổng học viên",
      dataIndex: "total_students",
      width: 120,
    });

    return childrenClasses;
  }, [financeData?.classNames]);

  const childrenClasses = useMemo(
    () => getChildrenClasses(),
    [getChildrenClasses]
  );

  const columns = useMemo(
    () => [
      {
        title: t("recordConsultant"),
        dataIndex: "fullname",
        fixed: "left",
      },
      {
        title: t("numberOfStudents"),
        dataIndex: "",
        children: childrenClasses,
      },
      {
        title: t("unPaid1"),
        dataIndex: "total_unpaid",
        width: 120,
      },
      {
        title: t("totalDiscount"),
        dataIndex: "total_discount",
        width: 120,
      },
      {
        title: t("received1"),
        dataIndex: "total_received",
        width: 120,
      },
      {
        title: t("kpi"),
        dataIndex: "kpi",
        width: 120,
      },
      {
        title: t("percent"),
        dataIndex: "percent_passed",
      },
    ],
    [childrenClasses, t]
  );

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className='input-table'>
      <>
        <div style={{ marginBottom: 16 }} className='table-header'>
          <div className='left finance-table-filter'>
            <h3
              style={{
                marginRight: 10,
              }}
            >
              Lọc theo:{" "}
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <Select
                defaultValue='ck'
                onChange={(value) => setFilterType(value)}
                style={{
                  width: width <= 640 ? "100%" : "auto",
                }}
              >
                <Select.Option value='ck'>Ngày chuyển khoản</Select.Option>
                <Select.Option value='kg'>Ngày khai giảng</Select.Option>
                <Select.Option value='nl'>Ngày nhập liệu</Select.Option>
              </Select>
              <DatePicker.RangePicker
                style={{
                  width: width <= 640 ? "100%" : "auto",
                }}
                format={"DD/MM/YYYY"}
                allowClear
                disabledDate={(current) =>
                  !isAdmin && current.year() < dayjs().year()
                }
                onChange={(date) => {
                  setStartDate(
                    date?.[0]?.format("YYYY-MM-DD")?.toString() ?? null
                  );
                  setEndDate(
                    date?.[1]?.format("YYYY-MM-DD")?.toString() ?? null
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ margin: "0", marginBottom: 10, wordBreak: "break-word" }}>
          Lưu ý: TVTS lọc theo Ngày khai giảng để theo dõi KPI
        </div>
        <Table
          showHeader={financeData?.cosultants?.length > 0}
          pagination={{ pageSize: 100 }}
          bordered
          sticky
          loading={isLoading}
          columns={columns}
          dataSource={financeData?.cosultants}
          scroll={{ x: "max-content" }}
          summary={(pageData) => {
            let studentsCount = new Map();
            let totalUnpaid = 0;
            let totalDiscount = 0;
            let totalStudents = 0;
            let totalReceived = 0;
            let totalPassed = 0;
            let countPassed = 0;

            pageData.forEach((props) => {
              const {
                total_unpaid,
                total_discount,
                total_received,
                total_students,
                percent_passed,
              } = props;
              Object.keys(props).forEach((key) => {
                if (key.toString().includes("Lớp")) {
                  studentsCount.set(
                    key,
                    (studentsCount.get(key) || 0) + props[key]
                  );
                }
              });
              totalUnpaid += parseInt(removeNonNumeric(total_unpaid));
              totalDiscount += parseInt(removeNonNumeric(total_discount));
              totalStudents += parseInt(
                removeNonNumeric(total_students || "0")
              );
              totalReceived += parseInt(removeNonNumeric(total_received));
              totalPassed += parseFloat(percent_passed) || 0;
              ++countPassed;
            });

            return (
              <Table.Summary>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan='1' align='center'>
                    {t("total")}
                  </Table.Summary.Cell>
                  {financeData?.classNames?.map((classObj, index) => (
                    <Table.Summary.Cell key={index}>
                      <Text type='danger'>
                        {studentsCount.get(classObj.class)}
                      </Text>
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell>
                    <Text type='danger'>{addCommas(totalStudents)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type='danger'>{addCommas(totalUnpaid)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type='danger'>{addCommas(totalDiscount)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type='danger'>{addCommas(totalReceived)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type='danger'>
                      {totalPassed / countPassed ? (
                        <>
                          {t("avg")}:{" "}
                          {Math.round((totalPassed / countPassed) * 100) / 100}%
                        </>
                      ) : null}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </>
    </div>
  );
};

export default FinanceTable;
