import {
  ContactsOutlined,
  CopyOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import {
  Bell,
  Box,
  DollarSign,
  FileText,
  Globe,
  Home,
  PieChart,
  Rss,
  Settings,
  Shield,
  UserCheck,
  UserPlus,
  UserX,
  Users,
  Zap,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { userInfo } from "../../utils";
import "./SiderMenu.css";

const { SubMenu } = Menu;

const SiderMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isAccountant = userInfo?.user?.role.includes("accountant");
  const isCollaborator = userInfo?.user?.isCollaborator === true;
  const isOnlyClass =
    userInfo?.user?.role.includes("only_class") ||
    userInfo?.user?.role.includes("cskh");
  const isNotAdmin = !userInfo?.user?.role.includes("admin");
  const isOnlySetClass = userInfo?.user?.role.includes("only_set_class");
  const isUnkown1 = userInfo?.user?.role.includes("unknown_1");
  const isSalesMn = userInfo?.user?.role.includes("sales_mn");
  const isHr = userInfo?.user?.role.includes("hr");
  const isSales = userInfo?.user?.role.includes("sales");

  return (
    <>
      {userInfo && (
        <Menu
          className='sider'
          defaultSelectedKeys={[location.pathname.replace("/", "")]}
          defaultOpenKeys={["sub-data-records", "sub2", "sub3"]}
          mode='inline'
        >
          <Menu.Item
            key='home'
            icon={<Home size={14} color='rgba(28, 29, 33, 0.88)' />}
            className='ant-menu-submenu-title'
          >
            <Link to='/'>{t("home").toUpperCase()}</Link>
          </Menu.Item>
          <SubMenu
            key='sub-data-records'
            icon={<Box size={14} color='rgba(28, 29, 33, 0.88)' />}
            title={t("crm").toUpperCase()}
          >
            <SubMenu
              title={t("customers").toUpperCase()}
              key='sub-data-customers'
              icon={<CopyOutlined />}
            >
              <Menu.Item
                key='import'
                icon={<UserPlus size={14} color='rgba(28, 29, 33, 0.88)' />}
                hidden={isAccountant || isOnlyClass || isOnlySetClass}
              >
                <Link to='/import'>{t("dataEntry")}</Link>
              </Menu.Item>
              {userInfo?.user?.username !== "TV0048" && (
                <>
                  <Menu.Item
                    key='students-registered'
                    icon={
                      <UserCheck size={14} color='rgba(28, 29, 33, 0.88)' />
                    }
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=registered'>
                      {t("registeredCustomers")}
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item
                    key='vip-business'
                    icon={
                      <UserCheck size={14} color='rgba(28, 29, 33, 0.88)' />
                    }
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/vip-business'>{t("vipBusiness")}</Link>
                  </Menu.Item> */}
                  <Menu.Item
                    key='students-unRegistered'
                    icon={<UserX size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=unRegistered'>
                      {t("unregisteredCustomers")}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key='students-studying'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=studying'>
                      {t("enrolledCustomers")}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key='students-L1-to-L2'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=L1L2'>{t("L1L2")}</Link>
                  </Menu.Item>
                  <Menu.Item
                    key='students-L2-to-L3'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=L2L3'>{t("L2L3")}</Link>
                  </Menu.Item>
                  {/* <Menu.Item
                    key='students-T+1'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=T1'>{t('T1')}</Link>
                  </Menu.Item> */}
                  <Menu.Item
                    key='students-reservation'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=reservation'>
                      {t("reserved")}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key='students-drop'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=drop'>{t("dropped")}</Link>
                  </Menu.Item>
                  {/* <Menu.Item
                    key='students-T+15'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=T15'>{t('T15')}</Link>
                  </Menu.Item>
                  <Menu.Item
                    key='students-T+30'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=T30'>{t('T30')}</Link>
                  </Menu.Item>
                  <Menu.Item
                    key='students-T+90'
                    icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                    hidden={isOnlyClass || isOnlySetClass || isUnkown1}
                  >
                    <Link to='/students?filter=T90'>{t('T90')}</Link>
                  </Menu.Item> */}
                </>
              )}
            </SubMenu>
            <Menu.Item
              key='classes'
              icon={<FileText size={14} color='rgba(28, 29, 33, 0.88)' />}
              hidden={
                isAccountant ||
                isCollaborator ||
                userInfo?.user?.username === "TV0048"
              }
            >
              <Link to='/classes'>{t("classes")}</Link>
            </Menu.Item>
            <Menu.Item
              key='finance'
              icon={<DollarSign size={14} color='rgba(28, 29, 33, 0.88)' />}
              hidden={
                isAccountant ||
                isCollaborator ||
                isOnlyClass ||
                isOnlySetClass ||
                isUnkown1 ||
                userInfo?.user?.username === "TV0048"
              }
            >
              <Link to='/finance'>{t("manageFinances")}</Link>
            </Menu.Item>
            <Menu.Item
              key='ranking'
              icon={<TrophyOutlined />}
              hidden={isNotAdmin && !isSales && !isSalesMn}
            >
              <Link to='/ranking'>{t("ranking")}</Link>
            </Menu.Item>
            <Menu.Item
              key='statistics'
              icon={<PieChart size={14} color='rgba(28, 29, 33, 0.88)' />}
              hidden={
                (isNotAdmin &&
                  !isSalesMn &&
                  userInfo?.user?.username !== "TVTS4" &&
                  userInfo?.user?.username !== "TV0048") ||
                isOnlySetClass ||
                isUnkown1
              }
            >
              <Link to='/statistics'>{t("statistics")}</Link>
            </Menu.Item>
            <Menu.Item
              key='requests_sent'
              icon={<Zap size={14} color='rgba(28, 29, 33, 0.88)' />}
              hidden={userInfo?.user?.username === "TV0048"}
            >
              <Link to='/requests/me'>{t("requests")}</Link>
            </Menu.Item>
          </SubMenu>
          {(!isNotAdmin ||
            userInfo?.user?.role.includes("dev") ||
            isUnkown1 ||
            isOnlySetClass ||
            userInfo?.user?.username === "TVTS4" ||
            isHr ||
            isSalesMn) && (
            <SubMenu
              key='sub2'
              icon={<Shield size={14} color='rgba(28, 29, 33, 0.88)' />}
              title='ADMIN'
            >
              <Menu.Item hidden={isSalesMn || isHr} icon={<ContactsOutlined />}>
                <Link to='/classes/setting'>{t("manageClasses")}</Link>
              </Menu.Item>
              <Menu.Item
                hidden={isOnlySetClass || isUnkown1 || isHr}
                icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
              >
                <Link to='/cosultants/setting'>{t("manageConsultants")}</Link>
              </Menu.Item>
              <Menu.Item
                icon={<Bell size={14} color='rgba(28, 29, 33, 0.88)' />}
                hidden={
                  isOnlySetClass ||
                  userInfo?.user?.username === "TVTS4" ||
                  isUnkown1 ||
                  isSalesMn ||
                  isHr
                }
              >
                <Link to='/notifications/setting'>
                  {t("manageNotifications")}
                </Link>
              </Menu.Item>
              <Menu.Item
                hidden={isNotAdmin}
                icon={<Zap size={14} color='rgba(28, 29, 33, 0.88)' />}
              >
                <Link to='/manage/requests'>{t("manageRequests")}</Link>
              </Menu.Item>
              <Menu.Item
                hidden={isNotAdmin && !isSalesMn}
                icon={<DollarSign size={14} color='rgba(28, 29, 33, 0.88)' />}
              >
                <Link to='/manage/revenue'>{t("revenueManagement")}</Link>
              </Menu.Item>
              <Menu.Item
                hidden={isNotAdmin && !isUnkown1 && !isHr}
                icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
              >
                <Link to='/users'>{t("humanResourceManagement")}</Link>
              </Menu.Item>
            </SubMenu>
          )}
          {(!isNotAdmin || userInfo?.user?.role.includes("dev")) && (
            <SubMenu
              key='sub3'
              icon={<Settings size={14} color='rgba(28, 29, 33, 0.88)' />}
              title={`${t("settings").toUpperCase()}`}
            >
              <Menu.Item key='pancake-config'>
                <Link to='/system-configs/pancake'>
                  <img
                    src='/pancake-logo.svg'
                    width='100'
                    height='20'
                    alt='Pancake'
                  />
                </Link>
              </Menu.Item>
              <Menu.Item
                key='channel-config'
                icon={<Globe size={14} color='rgba(28, 29, 33, 0.88)' />}
              >
                <Link to='/system-configs'>{t("general")}</Link>
              </Menu.Item>
              <Menu.Item
                key='extras'
                icon={<Rss size={14} color='rgba(28, 29, 33, 0.88)' />}
              >
                <Link to='/extras'>{t("extras")}</Link>
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
      )}
    </>
  );
};

export default SiderMenu;
