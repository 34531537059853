import { Select, Table, Typography } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { STATISTICS_TYPE_MAPPING } from "../../constants";
import { config, userInfo } from "../../utils";
import addCommas from "../../utils/addCommas";
import removeNonNumeric from "../../utils/removeNonNumeric";
import Loader from "../Common/Loader/Loader";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import "./index.css";

const { Option } = Select;

const Statistics = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [type, setType] = useState("facility");
  const [time, setTime] = useState(dayjs().year());
  const [years, setYears] = useState([]);

  const [loading, setLoading] = useState(false);
  const [disableSelect, setDisableSelect] = useState(false);

  const [datasets, setDatasets] = useState([]);
  const [pieData, setPieData] = useState({});
  const [labels, setLabels] = useState([]);
  const [dataRegister, setDataRegister] = useState("all");
  const [updatedTime, setUpdatedTime] = useState();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // get array of years
  useEffect(() => {
    if (userInfo?.user?.role.includes("sales_mn")) {
      setYears([2023]);
      return;
    }
    const currYear = dayjs().year();
    const yearsArr = [];
    for (let i = 2021; i <= currYear; ++i) {
      yearsArr.push(i);
    }
    setYears(yearsArr);
  }, [JSON.stringify(userInfo)]);

  const fetchData = async (time, type) => {
    setLoading(true);
    setDisableSelect(true);

    const url = `${process.env.REACT_APP_API_URL}/v1/statistics`;
    const { data } = await axios.post(url, { year: time, type }, config);
    console.log("data: ", data);

    setDatasets(data.data?.statistics?.datasets);
    setPieData(data.data?.statistics?.pieData);
    setLabels(data.data?.statistics?.labels);
    setUpdatedTime(data.data?.last_updated);

    setLoading(false);
    setDisableSelect(false);
  };

  useEffect(() => {
    fetchData(time, type);
  }, [time, type]);

  // chart configs
  const options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    borderWidth: 1.5,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: "nearest",
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: STATISTICS_TYPE_MAPPING[type],
      },
    },
  };

  const getLineChartDatasets = () => {
    const newDatasets = [...JSON.parse(JSON.stringify(datasets))];

    return dataRegister === "all"
      ? newDatasets
      : dataRegister === "registered"
      ? newDatasets?.map((set) => {
          set.data = set.dataRegistered;
          return set;
        })
      : newDatasets?.map((set) => {
          set.data = set.dataUnRegistered;
          return set;
        }) || [];
  };

  const lineChartData = {
    labels,
    datasets: getLineChartDatasets(),
  };

  console.log("lineChartData", lineChartData);

  const pieLabels =
    Object.keys(
      (dataRegister === "all"
        ? pieData?.total
        : dataRegister === "registered"
        ? pieData?.registered
        : pieData?.unRegistered) || {}
    ) || [];

  const pieChartData = {
    labels: pieLabels,
    datasets: [
      {
        label: "#",
        data:
          Object.values(
            (dataRegister === "all"
              ? pieData?.total
              : dataRegister === "registered"
              ? pieData?.registered
              : pieData?.unRegistered) || {}
          ) || [],
        backgroundColor: pieLabels.map(
          (label) =>
            datasets?.find((item) => item.label === label)?.backgroundColor
        ),
        borderColor: pieLabels.map(
          (label) => datasets?.find((item) => item.label === label)?.borderColor
        ),
        borderWidth: 1,
      },
    ],
  };

  const handleTypeChange = (value) => {
    setType(value);
  };

  const handleTimeChange = (value) => {
    setTime(value);
  };

  const handleDataRegisterChange = (value) => {
    setDataRegister(value);
  };

  const dataSource = [];
  const columns = [
    {
      title: t("month"),
      dataIndex: "month",
      key: "month",
      width: 80,
    },
  ];

  datasets.forEach((dataset) => {
    columns.push({
      title: dataset.label,
      dataIndex: dataset.label,
      key: dataset.label,
      width: width >= 600 ? 100 : 130,
      // render: (text) => (!text.toString().includes('%') ? `${text} ()` : text),
    });
  });

  columns.push({
    title: t("total"),
    dataIndex: "total",
    key: "total",
    width: width >= 600 ? 100 : 130,
    render: (text) => (
      <span style={{ color: "red" }}>
        <b>{text}</b>
      </span>
    ),
  });

  labels
    .sort((a, b) => a.slice(5) - b.slice(5))
    .forEach((label) => {
      let dataSlice = {};
      let dataSliceReg = {};
      let dataSliceSales = {};
      let total = 0;
      let totalReg = 0;
      let totalSales = 0;

      datasets?.forEach((dataset) => {
        const monthIndex = label.slice(6) - 1; // 0, 1, 2,...
        dataSlice[dataset.label] = dataset.data?.[monthIndex] || 0;
        dataSliceReg[dataset.label] =
          dataset.data?.[monthIndex] > 0
            ? `${
                Math.round(
                  ((dataset.dataRegistered?.[monthIndex] || 0) /
                    (dataset.data?.[monthIndex] || 0)) *
                    100 *
                    100
                ) / 100
              }% (${dataset.dataRegistered?.[monthIndex] || 0}/${
                dataset.data?.[monthIndex] || 0
              })`
            : "∞";
        dataSliceSales[dataset.label] =
          addCommas(dataset.dataSales?.[monthIndex]) || 0;
        total += dataset.data?.[monthIndex] || 0;
        totalReg += dataset.dataRegistered?.[monthIndex] || 0;
        totalSales += dataset.dataSales?.[monthIndex] || 0;
      });

      Object.entries(dataSlice).forEach(([key, value]) => {
        dataSlice[key] = `${value} (${
          Math.round((value / total) * 100 * 100) / 100
        }%)`;
      });
      dataSource.push({
        key: label,
        month: label,
        ...dataSlice,
        total,
      });

      dataSource.push({
        key: label,
        month: "ĐK/Tổng",
        ...dataSliceReg,
        total:
          totalReg > 0
            ? `${
                Math.round((totalReg / total) * 100 * 100) / 100
              }% (${totalReg}/${total})`
            : "∞",
      });

      if (userInfo?.user?.role.includes("admin")) {
        Object.entries(dataSliceSales).forEach(([key, value]) => {
          dataSliceSales[key] = `${value} (${
            Math.round(
              ((parseInt(removeNonNumeric(value)) || 0) / totalSales) *
                100 *
                100
            ) / 100
          }%)`;
        });
        dataSource.push({
          key: label,
          month: "Doanh số",
          ...dataSliceSales,
          total: addCommas(totalSales),
        });
      }
    });

  console.log("labels", labels);
  console.log("datasets", datasets);
  console.log("dataSource", dataSource);

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          y: 20,
          opacity: 0,
        }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: 20,
          opacity: 0,
          position: "absolute",
        }}
        transition={{ duration: 0.5 }}
      >
        <div className='statistics'>
          <div className='statistics-header'>
            <div className='statistics-header__main'>
              <Typography.Text className='statistics-type'>
                {t("filterBy")}:{" "}
                <Select
                  style={{ width: 200 }}
                  defaultValue='facility'
                  onChange={handleTypeChange}
                  disabled={disableSelect}
                >
                  {Object.entries(STATISTICS_TYPE_MAPPING).map(
                    ([key, value]) => (
                      <Option value={key}>{value}</Option>
                    )
                  )}
                </Select>
              </Typography.Text>
              <div className='statistics-register'>
                <Typography.Text>Đăng ký học: </Typography.Text>
                <Select
                  disabled={disableSelect}
                  style={{ width: 200 }}
                  defaultValue='all'
                  onChange={handleDataRegisterChange}
                >
                  <Option value='all'>{t("all")}</Option>
                  <Option value='registered'>Đã đăng ký</Option>
                  <Option value='unRegistered'>Chưa đăng ký</Option>
                </Select>
              </div>
              <div className='statistics-time'>
                <Typography.Text>Thời gian: </Typography.Text>
                <Select
                  disabled={disableSelect}
                  style={{ width: 120 }}
                  defaultValue={time}
                  onChange={handleTimeChange}
                >
                  {years.map((year) => (
                    <Option value={year}>{year}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <Typography.Text>
              <small>
                Chú ý: <br />- undefined hoặc trống là những bản ghi chưa update
                đầy đủ thông tin. <br />- Click vào ô chữ nhật để loại bỏ đối
                tượng biểu diễn.
              </small>
              <br />
              {!!updatedTime && (
                <small style={{ marginRight: 20 }}>
                  {t("recordLastUpdated")}:{" "}
                  {dayjs(updatedTime).format("HH:mm, DD/MM/YYYY")}
                </small>
              )}
            </Typography.Text>
          </div>
          <div
            className='chart-section'
            style={{ height: type === "address" ? "500px" : "350px" }}
          >
            <Typography.Text className='heading-chart'>
              Tổng quan số liệu
            </Typography.Text>
            {!loading ? (
              <PieChart data={pieChartData} options={options} />
            ) : (
              <Loader />
            )}
          </div>
          <div
            className='chart-section'
            style={{ height: "650px", marginTop: "30px" }}
          >
            <Typography.Text className='heading-chart'>
              Thống kê chi tiết
            </Typography.Text>
            {!loading ? (
              <LineChart data={lineChartData} options={options} />
            ) : (
              <Loader />
            )}
          </div>
          <div className='chart-section' style={{ marginTop: "30px" }}>
            <Typography.Text className='heading-chart'>
              Bảng tổng kết
            </Typography.Text>
            <br />
            <br />
            {!loading ? (
              <Table
                className='statistics-table'
                showHeader={dataSource?.length > 0}
                dataSource={dataSource}
                columns={columns}
                rowClassName={(_, index) => {
                  if (userInfo?.user?.role.includes("admin")) {
                    return (index + 1) % 3 === 1 ? "" : "data-from-web";
                  }
                  return index % 2 !== 0 ? "data-from-web" : "";
                }}
                pagination={false}
                scroll={{ x: "max-content" }}
                showSorterTooltip={true}
                sticky
                summary={() => {
                  const total = [];
                  let totalReg = 0,
                    totalAll = 0,
                    totalSales = 0;

                  datasets?.forEach(
                    ({
                      label,
                      data,
                      dataRegistered,
                      dataUnRegistered,
                      dataSales,
                    }) => {
                      total.push({
                        label,
                        totalData: data?.reduce((acc, curr) => {
                          totalAll += curr;
                          return acc + curr;
                        }, 0),
                        totalDataRegistered: dataRegistered?.reduce(
                          (acc, curr) => {
                            totalReg += curr;
                            return acc + curr;
                          },
                          0
                        ),
                        totalDataUnRegistered: dataUnRegistered?.reduce(
                          (acc, curr) => acc + curr,
                          0
                        ),
                        totalSales: dataSales?.reduce((acc, curr) => {
                          totalSales += curr;
                          return acc + curr;
                        }, 0),
                      });
                    }
                  );

                  console.log("total", total);

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>{t("total")}</Table.Summary.Cell>
                        {total.map((item) => (
                          <Table.Summary.Cell>
                            <Typography.Text type='danger'>
                              <p>
                                {item.totalDataRegistered}/{item.totalData} (
                                {Math.round(
                                  (item.totalDataRegistered / item.totalData) *
                                    100 *
                                    100
                                ) / 100}
                                %)
                              </p>
                              <p>{addCommas(item.totalSales)}</p>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        ))}
                        <Table.Summary.Cell>
                          <Typography.Text type='danger'>
                            <p>
                              {totalReg}/{totalAll} (
                              {Math.round((totalReg / totalAll) * 100 * 100) /
                                100}
                              %)
                            </p>
                            <p>{addCommas(totalSales)}</p>
                          </Typography.Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Statistics;
