import { PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
} from "antd";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AGE_OPTIONS } from "../../constants";
import { useOptions } from "../../hooks/useOptions";
import { useBoundStore } from "../../store/useBoundStore";
import { config, potentials, userInfo } from "../../utils";
import locations from "../../utils/locations";
import { sex } from "../../utils/sex";
import { statusRecord } from "../../utils/status";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const CreateForm = ({ visible, onCreate, onCancel, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const typingTimeoutRef = useRef(null);
  const [isNewPhone, setIsNewPhone] = useState(true);
  const [ages, setAges] = useState(AGE_OPTIONS);
  const [age, setAge] = useState("");
  const ageRef = useRef(null);

  const requiredIfNotAdmin = !userInfo?.user?.role.includes("admin");

  const ranking = useBoundStore((state) => state.ranking);

  const { data: channels } = useOptions("channels", { enabled: false });
  const { data: classes } = useOptions("classes", {
    enabled: false,
  });
  const { data: cosultants } = useOptions("cosultants", {
    enabled: false,
  });

  const handlePhoneChange = (e) => {
    const phone = e.target.value;

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      const checkPhoneNumber = async () => {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/records/check_phone`,
          { phone },
          config
        );

        setIsNewPhone(data.data.is_new_phone);
      };
      checkPhoneNumber();
    }, 300);
  };

  const onAgeChange = (value) => {
    setAge(value);
  };

  const addAge = (e) => {
    e.preventDefault();
    setAges([...ages, age]);
    setAge("");
    setTimeout(() => {
      ageRef.current?.focus();
    }, 0);
  };

  return (
    <Modal
      width={600}
      open={visible}
      title={t("newRecordModal")}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t("cancel")}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                if (values.expected_class) {
                  values.class = classes?.find(
                    (item) => item.title === values.expected_class
                  )?.value;
                }
                if (age) {
                  values.year_of_birthday = age;
                }
                console.log("values", values);
                form.resetFields();
                onCreate(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          {t("ok")}
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical' name='form_in_modal'>
        <Collapse
          defaultActiveKey={
            window.location.pathname.includes("import")
              ? ["personalInfo", "registrationInfo", "otherInfo"]
              : ["registrationInfo", "otherInfo", "billingInfo"]
          }
        >
          <Panel key='personalInfo' header={t("personalInfo")}>
            <div className='inline-input'>
              <Form.Item
                name='name'
                label={t("recordName")}
                rules={[
                  {
                    required: requiredIfNotAdmin,
                    message: "Trường này là bắt buộc!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='email'
                label={t("recordEmail")}
                rules={[
                  {
                    required: false,
                    message: "Trường này là bắt buộc!",
                  },
                  {
                    type: "email",
                    message: "Email không hợp lệ!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className='inline-input'>
              <Form.Item
                name='phone'
                label={t("recordPhone")}
                rules={[
                  {
                    required: requiredIfNotAdmin,
                    message: "Trường này là bắt buộc!",
                  },
                ]}
              >
                <Input onChange={handlePhoneChange} />
              </Form.Item>
              <Form.Item
                style={{ width: "100%" }}
                name='year_of_birthday'
                label={t("recordDateOfBirthday")}
                rules={[
                  {
                    required: requiredIfNotAdmin,
                    message: "Trường này là bắt buộc!",
                  },
                ]}
              >
                <Select
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder=''
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={1900}
                          minLength={4}
                          maxLength={4}
                          placeholder='Nhập năm sinh'
                          ref={ageRef}
                          value={age}
                          onChange={onAgeChange}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button
                          type='text'
                          icon={<PlusOutlined />}
                          onClick={addAge}
                          disabled={!age}
                        >
                          Thêm
                        </Button>
                      </Space>
                    </>
                  )}
                  options={ages.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Form.Item>
            </div>
            {!isNewPhone && (
              <Alert
                message='SĐT này đã có trên hệ thống, tiếp tục nhập hoặc huỷ bỏ.'
                type='warning'
              />
            )}
            <div className='inline-input'>
              <div id='add-popup-address'>
                <Form.Item
                  name='address'
                  label={t("recordAddress")}
                  rules={[
                    {
                      required: requiredIfNotAdmin,
                      message: "Trường này là bắt buộc!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    getPopupContainer={() =>
                      document.getElementById("add-popup-address")
                    }
                    style={{
                      width: "100%",
                    }}
                    options={locations}
                    placeholder={t("typeToSearch")}
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item
                name='sex'
                label={t("recordSex")}
                rules={[
                  {
                    required: requiredIfNotAdmin,
                    message: "Trường này là bắt buộc!",
                  },
                ]}
              >
                <Select>
                  <Option value='Chưa chọn'>Chưa chọn</Option>
                  {sex &&
                    sex.map((sexObj) => (
                      <Option key={sexObj.value} value={sexObj.value}>
                        {sexObj.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className='inline-input'>
              <Form.Item
                name='occupation'
                label={t("recordOccupation")}
                rules={[
                  {
                    required: requiredIfNotAdmin,
                    message: "Trường này là bắt buộc!",
                  },
                  {
                    min: 4,
                    message: "Nhập ít nhất 4 ký tự!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='purpose'
                label={t("recordPurpose")}
                rules={[
                  {
                    required: requiredIfNotAdmin,
                    message: "Trường này là bắt buộc!",
                  },
                  {
                    min: 20,
                    message: "Nhập ít nhất 20 ký tự!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Panel>
          <Panel key='registrationInfo' header={t("registrationInfo")}>
            <div className='inline-input'>
              <div id='add-popup-col'>
                <Form.Item
                  name='cosultant'
                  label={t("consultant")}
                  rules={[
                    {
                      required: true,
                      message: "Trường này là bắt buộc!",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("add-popup-col")
                    }
                    placeholder={t("typeToSearch")}
                    showSearch
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    {(!!ranking ? ranking : cosultants)?.map((item) => (
                      <Option key={item._id} value={item.fullname}>
                        {item.fullname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                name='is_register'
                label={t("enrollInClass")}
                valuePropName='checked'
              >
                <Checkbox>{t("enrollInClass")}</Checkbox>
              </Form.Item>
            </div>
            <div className='inline-input'>
              <div id='add-popup-expcl'>
                <Form.Item
                  name='expected_class'
                  label={t("recordExpectedClass")}
                  rules={[
                    {
                      required: requiredIfNotAdmin,
                      message: "Trường này là bắt buộc!",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("add-popup-expcl")
                    }
                    placeholder={t("typeToSearch")}
                    showSearch
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    <Option value='Chưa chọn'>Chưa chọn</Option>
                    {classes?.map((option) => (
                      <Option key={option.value} value={option.title}>
                        {option.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Panel>
          <Panel key='otherInfo' header={t("otherInfo")}>
            <div className='inline-input'>
              <div id='add-popup-channel'>
                <Form.Item
                  name='channel'
                  label={t("recordChannel")}
                  rules={[
                    {
                      required: requiredIfNotAdmin,
                      message: "Trường này là bắt buộc!",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("add-popup-channel")
                    }
                    style={{
                      width: "100%",
                    }}
                    placeholder={t("typeToSearch")}
                    showSearch
                    allowClear
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    {channels?.map((item) => (
                      <Option key={item.value} value={item.title}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              {window.location.pathname.includes("import") && (
                <div id='add-popup-status'>
                  <Form.Item
                    name='status'
                    label={t("recordStatus")}
                    rules={[
                      {
                        required: requiredIfNotAdmin,
                        message: "Trường này là bắt buộc!",
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={() =>
                        document.getElementById("add-popup-status")
                      }
                      placeholder={t("typeToSearch")}
                      showSearch
                      allowClear
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    >
                      {statusRecord.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </div>
            <div className='inline-input'>
              <div id='add-popup-potential'>
                <Form.Item
                  name='potential'
                  label={t("recordPotentail")}
                  rules={[
                    {
                      required: requiredIfNotAdmin,
                      message: "Trường này là bắt buộc!",
                    },
                  ]}
                >
                  <Select
                    getPopupContainer={() =>
                      document.getElementById("add-popup-potential")
                    }
                  >
                    {potentials.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Panel>
          <Panel key='note' header={t("recordNote")}>
            <Form.Item name='note' label={t("recordNote1")}>
              <TextArea showCount />
            </Form.Item>
            <Form.Item name='note_1' label={t("recordNote2")}>
              <TextArea showCount />
            </Form.Item>
            <Form.Item name='note_gv' label={t("teacherNote")}>
              <TextArea showCount />
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};

export default React.memo(CreateForm);
