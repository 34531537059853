import {
  CaretDownOutlined,
  CaretUpOutlined,
  DollarOutlined,
  FileExcelOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  HeartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Divider,
  Image,
  Popover,
  Select,
  Skeleton,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Confetti from "react-confetti";
import CountUp from "react-countup";
import { Award, Hash, Star } from "react-feather";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import {
  MONTH_INDEX_NAME_MAPPING,
  STATISTICS_FIELDS_NAME_MAPPING,
} from "../../constants";
import { config, userInfo } from "../../utils";
import removeNonNumeric from "../../utils/removeNonNumeric";
import LineChart from "../Statistics/LineChart";
import { getBadgeStyles } from "../TopNotifications/TopNotifications";
import "./Ranking.css";
import SkeletonRanking from "./SkeletonRanking";
import SkeletonStatistic from "./SkeletonStatistic";

const { Text } = Typography;

const Ranking = () => {
  const { width, height } = useWindowSize();
  const handle = useFullScreenHandle();
  const { t } = useTranslation();

  const [data, setData] = useState();
  const [trending, setTrending] = useState();
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [year, setYear] = useState(dayjs().year());
  const [loading, setLoading] = useState(false);

  const years = useMemo(() => {
    const res = [];
    for (let i = dayjs().year(); i >= 2021; --i) {
      res.push(i);
    }
    return res;
  }, []);

  const currCosultant = useMemo(
    () =>
      data?.ranking?.find((cos) => cos.fullname === userInfo?.user.fullname) ||
      userInfo?.user,
    [data]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/v1/statistics/ranking`;
      const { data } = await axios.post(
        url,
        {
          month_index: monthIndex,
          year,
        },
        config
      );

      setData(data.data);
      setLoading(false);
    };

    fetchData();
  }, [monthIndex, year]);

  useEffect(() => {
    const fetchTrending = async () => {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/v1/statistics/trending`;
      const { data } = await axios.post(
        url,
        {
          year,
        },
        config
      );

      setTrending(data?.data?.trending);
      setLoading(false);
    };

    fetchTrending();
  }, [year]);

  const chartOptions = useMemo(
    () => ({
      elements: {
        line: {
          tension: 0.4,
        },
        point: {
          radius: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "nearest",
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      borderWidth: 1.5,
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  const renderTrendChart = useCallback(
    (id) => {
      const chartData = {
        labels: trending?.total_received?.[year]?.[id]
          ? Object.keys(trending.total_received[year][id]).map(
              (monthIndex) => `${t(MONTH_INDEX_NAME_MAPPING[monthIndex])}`
            )
          : [],
        datasets: [
          {
            data: trending?.total_received?.[year]?.[id]
              ? Object.values(trending.total_received[year][id]).map((x) =>
                  parseInt(removeNonNumeric(x))
                )
              : [],
            borderColor: "#5051ff",
            fill: "start",
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 80);
              gradient.addColorStop(0, "#dddeff");
              gradient.addColorStop(1, "#fff");
              return gradient;
            },
          },
        ],
      };

      return (
        <div
          style={{
            width: 120,
            height: 40,
          }}
        >
          <LineChart data={chartData} options={chartOptions} />
        </div>
      );
    },
    [chartOptions, t, trending?.total_received, year]
  );

  const renderOverviewChart = useCallback(() => {
    const hasFullDataViewPermission =
      userInfo?.user?.role.includes("admin") || false;
    const chartLabels = {};
    const colors = ["#5051ff", "#9966ff", "#ffcd56", "#ff6384", "#4bc0c0"];
    const chartDatasets =
      trending && year
        ? Object.keys(trending).reduce((acc, trendKey, index) => {
            let datasetObj = {
              data: [],
              label: `${t(STATISTICS_FIELDS_NAME_MAPPING[trendKey])} ${
                trendKey === "registered_rate" ? "(%)" : ""
              }`,
              fill: "start",
              borderColor: colors[index],
              backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 100);
                gradient.addColorStop(0, colors[index]);
                gradient.addColorStop(1, "#fff");
                return gradient;
              },
            };

            const fieldData = trending[trendKey][year] || {};
            let dataArray = [];

            if (hasFullDataViewPermission) {
              const res = {};
              Object.values(fieldData)?.forEach((trendObj) => {
                Object.entries(trendObj).forEach(([monthIndex, value]) => {
                  chartLabels[monthIndex] = t(`month${monthIndex}`);
                  if (
                    !["registered_rate", "percent_passed"].includes(trendKey)
                  ) {
                    res[monthIndex] = res[monthIndex] || 0;
                    res[monthIndex] += parseInt(removeNonNumeric(value)) || 0;
                  }
                });
              });
              dataArray = Object.values(res);
            } else {
              dataArray = Object.entries(
                fieldData[currCosultant._id] || {}
              )?.map(([key, value]) => {
                chartLabels[key] = t(`month${key}`);
                return ["registered_rate", "percent_passed"].includes(trendKey)
                  ? parseFloat(value.slice(0, -1))
                  : parseInt(removeNonNumeric(value)) || 0;
              });
            }

            datasetObj = { ...datasetObj, data: dataArray };
            acc.push(datasetObj);
            return acc;
          }, [])
        : [];

    const chartData = {
      labels: Object.values(chartLabels),
      datasets: chartDatasets,
    };

    return (
      <div style={{ marginTop: 30 }}>
        {chartDatasets.length && !loading ? (
          <LineChart
            data={chartData}
            options={{
              ...chartOptions,
              scales: {
                x: {
                  title: {
                    display: width >= 600,
                    text: t("month"),
                  },
                },
                y: {
                  stacked: true,
                  title: {
                    display: width >= 600,
                    text: `${t("totalSales1")} (đ)`,
                  },
                },
              },
              interaction: {
                mode: "nearest",
                axis: "x",
                intersect: false,
              },
            }}
          />
        ) : (
          <Skeleton active paragraph />
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trending, year, JSON.stringify(currCosultant), loading]);

  const renderStatisticsThisMonthBoxes = useCallback(() => {
    const statisticsDataThisMonth = data?.statisticsDataThisMonth;

    return (
      <>
        {statisticsDataThisMonth ? (
          <>
            <div className='boxes'>
              <div className='box box-students'>
                <p className='box-title'>
                  <TeamOutlined /> <span>{t("students")}</span>
                </p>
                {loading ? (
                  <Skeleton.Button active round />
                ) : (
                  <Text className='box-num'>
                    <CountUp
                      end={parseInt(
                        removeNonNumeric(
                          statisticsDataThisMonth.total_students_registed
                        )
                      )}
                      duration={1}
                    />
                  </Text>
                )}
              </div>
              <div className='box box-discount'>
                <p className='box-title'>
                  <HeartOutlined /> <span>{t("discount")}</span>
                </p>
                {loading ? (
                  <Skeleton.Button active round />
                ) : (
                  <Text className='box-num'>
                    <CountUp
                      end={parseInt(
                        removeNonNumeric(statisticsDataThisMonth.total_discount)
                      )}
                      separator='.'
                      duration={1}
                    />
                  </Text>
                )}
              </div>
              <div className='box box-unpaid'>
                <p className='box-title'>
                  <FileExcelOutlined /> <span>{t("unPaid2")}</span>
                </p>
                {loading ? (
                  <Skeleton.Button active round />
                ) : (
                  <Text className='box-num'>
                    <CountUp
                      end={parseInt(
                        removeNonNumeric(statisticsDataThisMonth.total_unpaid)
                      )}
                      separator='.'
                      duration={1}
                    />
                  </Text>
                )}
              </div>
              <div className='box box-recieved'>
                <p className='box-title'>
                  <DollarOutlined /> <span>{t("totalSales1")}</span>
                </p>
                {loading ? (
                  <Skeleton.Button active round />
                ) : (
                  <Text className='box-num'>
                    <CountUp
                      end={parseInt(
                        removeNonNumeric(statisticsDataThisMonth.total_received)
                      )}
                      separator='.'
                      duration={1}
                    />
                  </Text>
                )}
              </div>
            </div>
          </>
        ) : (
          <SkeletonStatistic />
        )}
      </>
    );
  }, [data?.statisticsDataThisMonth, t, loading]);

  const columns = [
    {
      title: t("rank"),
      dataIndex: "no",
      align: "center",
    },
    {
      title: t("recordConsultant"),
      width: 150,
      render: (_, record) => (
        <div className='flex items-center'>
          <Popover
            content={
              <>
                <div className='flex items-start'>
                  <h4>{record.fullname}</h4>
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      marginLeft: 8,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 12,
                      ...getBadgeStyles(record.no - 1),
                    }}
                  >
                    {record.no}
                  </div>
                </div>
                <div
                  style={{
                    color: "#888da8",
                  }}
                >
                  <div className='flex items-center'>
                    <Hash
                      size={14}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <span>Xếp hạng tháng: {record.no}</span>
                  </div>
                  <div className='flex items-center'>
                    <Award
                      size={14}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <span>Danh hiệu: (Coming soon)</span>
                  </div>
                  <div className='flex items-center'>
                    <Star
                      size={14}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <span>Điểm: (Coming soon)</span>
                  </div>
                </div>
              </>
            }
            title={
              record.avatar ? (
                <div>
                  <Image
                    src={record.avatar || ""}
                    width={200}
                    height={200}
                    style={{ objectFit: "cover" }}
                  />
                </div>
              ) : null
            }
            className='flex items-center'
          >
            <Avatar
              size='large'
              icon={<UserOutlined />}
              src={record.avatar || null}
              style={{ marginRight: 20 }}
            />
            <div style={{ whiteSpace: "nowrap" }}>{record.fullname}</div>
          </Popover>
        </div>
      ),
      align: "center",
    },
    {
      title: t("totalSales1"),
      dataIndex: "total_received",
      key: "total_received",
      render: (text, record) => <span>{text}</span>,
      align: "center",
    },
    {
      title: t("KPI"),
      dataIndex: "kpi",
      key: "kpi",
      render: (text, record) => <span>{text}</span>,
      align: "center",
    },
    {
      title: "% KPI",
      dataIndex: "percent_passed",
      render: (text, record) => <span>{text}</span>,
      key: "percent_passed",
      align: "center",
    },
    {
      title: (
        <>
          {t("lastMonth")} <br /> ({t("totalSales1")})
        </>
      ),
      key: "compare_month_before",
      render: (text, record) => (
        <div
          style={{
            borderRadius: "5px",
            padding: "5px",
            fontWeight: 500,
            color:
              record.compare_month_before < 0
                ? "#ea3943"
                : record.compare_month_before === 0
                ? "black"
                : "#16c784",
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          {record.compare_month_before === "***" ? (
            <span>***</span>
          ) : (
            <div className='flex items-center'>
              {record.compare_month_before > 0 ? (
                <>
                  <CaretUpOutlined style={{ marginRight: 5 }} />
                  <div>{record.compare_month_before}</div>
                </>
              ) : record.compare_month_before === 0 ? (
                <Text>0</Text>
              ) : (
                <>
                  <CaretDownOutlined style={{ marginRight: 5 }} />
                  <div>{Math.abs(record.compare_month_before)}</div>
                </>
              )}
              <div
                className={`percent-sign ${
                  record.compare_month_before === 0 && "zero"
                }`}
              >
                %
              </div>
            </div>
          )}
        </div>
      ),
      align: "center",
    },
    {
      title: t("totalData"),
      dataIndex: "total_students",
      key: "total_students",
      render: (text, record) => <span>{text}</span>,
      align: "center",
    },
    {
      title: t("customers1"),
      dataIndex: "total_students_registed",
      key: "total_students_registed",
      render: (text, record) => <span>{text}</span>,
      align: "center",
    },
    {
      title: t("conversionRate"),
      dataIndex: "registered_rate",
      key: "registered_rate",
      render: (text) => <span>{text}</span>,
      align: "center",
    },
    {
      title: `${year}`,
      width: 200,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {renderTrendChart(record._id)}
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          y: 20,
          opacity: 0,
        }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: 20,
          opacity: 0,
          position: "absolute",
        }}
        transition={{ duration: 0.5 }}
      >
        <div className='wrapper ranking-wrapper'>
          <div className='ranking-filter'>
            <div className='ranking-filter-items'>
              <b style={{ marginRight: 20, marginTop: 10 }}>{t("filterBy")}</b>
              <div className='ranking-filter-item'>
                <div style={{ marginRight: 5 }}>{t("year")}:</div>
                <Select
                  disabled={loading}
                  placeholder={t("year")}
                  style={{ width: 120 }}
                  defaultValue={year}
                  onChange={(value) => setYear(value)}
                >
                  {years.map((year) => (
                    <Select.Option
                      disabled={
                        !userInfo?.user?.role.includes("admin") &&
                        year !== dayjs().year()
                      }
                      value={year}
                    >
                      {year}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className='ranking-filter-item'>
                <div style={{ marginRight: 5 }}>{t("month")}:</div>
                <Select
                  disabled={loading}
                  placeholder={t("month")}
                  style={{ width: 120 }}
                  defaultValue={monthIndex}
                  onChange={(value) => setMonthIndex(value)}
                >
                  {Array.from(
                    {
                      length:
                        year === dayjs().year() ? dayjs().month() + 1 : 12,
                    },
                    (_, i) => i + 1
                  ).map((month) => (
                    <Select.Option value={month - 1}>{month}</Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className='ranking-note'>
              {data && (
                <small style={{ marginRight: 20 }}>
                  {t("recordLastUpdated")}:{" "}
                  {dayjs(data.last_updated).format("HH:mm, DD/MM/YYYY")} (
                  {t("every30m")})
                </small>
              )}
            </div>
          </div>
          <div className='ranking-top-block'>
            <div className='ranking-statistics'>
              <div className='flex items-center justify-between'>
                <div>
                  <h1>{`${t("overview")} ${year}`}</h1>
                </div>
              </div>
              {renderOverviewChart()}
            </div>
            <div className='ranking-info'>
              <h1>{`${t("statistics")} ${monthIndex + 1}/${year}`}</h1>
              {renderStatisticsThisMonthBoxes()}
            </div>
          </div>
          <div className='ranking-bottom-block'>
            <FullScreen handle={handle}>
              <div className='ranking-table-wrapper'>
                <div className='flex items-center justify-between'>
                  <h1 className='flex items-center' style={{ marginBottom: 0 }}>
                    <p style={{ marginBottom: 0 }}>
                      {t("ranking")} {`${monthIndex + 1}/${year}`}
                    </p>
                  </h1>
                  <div className='flex items-center justify-between'>
                    {handle.active ? (
                      <FullscreenExitOutlined onClick={handle.exit} />
                    ) : (
                      <FullscreenOutlined onClick={handle.enter} />
                    )}
                  </div>
                </div>

                {data && !loading ? (
                  <>
                    <Confetti recycle={false} width={width} height={height} />
                    <Table
                      className='ranking-table'
                      showHeader={data.ranking?.length > 0}
                      columns={columns}
                      dataSource={data.ranking}
                      style={{
                        fontWeight: 500,
                      }}
                      pagination={{
                        pageSize: 20,
                      }}
                      rowClassName={(record) =>
                        record.no === 1 ? "rank-top" : ""
                      }
                      scroll={{ x: "max-content" }}
                    />
                    <Divider> {t("note")} </Divider>
                    <div className='note'>
                      <Text>
                        <p>
                          - Bảng xếp hạng tính doanh số đạt được trong tháng,
                          dựa theo Ngày Chuyển Khoản (Đã hoàn thành hoặc Còn
                          nợ).
                        </p>
                        <p>
                          - KPI lương do leader đặt ra tính theo Ngày Chuyển
                          Khoản (bao gồm cả học viên Hoàn thành học phí từ tháng
                          trước).
                        </p>
                        <p>
                          - % hoa hồng tính tất cả học viên đã hoàn thành 100%
                          học phí trong tháng.
                        </p>
                      </Text>
                    </div>
                  </>
                ) : (
                  <SkeletonRanking />
                )}
              </div>
            </FullScreen>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Ranking;
