import axios from "axios";
import { config } from "../utils";

export const getOptions = async (name) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/v1/options`,
    {
      name,
    },
    config
  );
  return data.data;
};
