import dayjs from 'dayjs';

const getScholarship = (classEnd, width = '1100px') => {
  return `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Scholarship</title>
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tomorrow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

      .scholarship-wrapper * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Be Vietnam Pro', sans-serif;
      }

      .scholarship-wrapper p {
        margin-bottom: 0 !important;
      }

      .scholarship-wrapper {
        position: relative;
        width: ${width};
        height: 777px;
        margin: 0 auto;

        background-image: url('./hb-base.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .scholarship-expire {
        font-size: 16px;
        font-style: italic;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 445px;
        color: black;
      }
    </style>
  </head>
  <body>
    <div class="scholarship-wrapper">
      <div class="scholarship-expire">Học bổng có giá trị đến ${
        classEnd ? dayjs(classEnd).add(60, 'day').format('DD/MM/YYYY') : ''
      }</div>
    </div>
  </body>
</html>
  `;
};

export default getScholarship;
