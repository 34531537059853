import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import readNumber from "read-vn-number";
import { useOptions } from "../../../hooks/useOptions";
import addCommas from "../../../utils/addCommas";
import removeNonNumeric from "../../../utils/removeNonNumeric";

const CreateSubClassForm = ({
  visible = false,
  onCreate,
  onCancel,
  loading = false,
  mainClass,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [price, setPrice] = useState();

  const { data: teachers } = useOptions("teachers", { initialData: [] });
  const { data: assistants } = useOptions("assistants", { initialData: [] });

  const handleFormValuesChange = (_, allValues) => {
    form.setFieldsValue({
      class_fee: addCommas(removeNonNumeric(allValues.class_fee)),
    });
    setPrice(addCommas(removeNonNumeric(allValues.class_fee)));
  };

  return (
    <Modal
      width={600}
      open={visible}
      title={t("newRecordModal")}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t("cancel")}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                if (values.class_teacher?.value) {
                  values.class_teacher = values.class_teacher.value;
                }
                if (values.class_teaching_assistant?.value) {
                  values.class_teaching_assistant =
                    values.class_teaching_assistant.value;
                }

                form.resetFields();
                console.log("values", values);
                onCreate(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          {t("ok")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        onValuesChange={handleFormValuesChange}
        initialValues={{
          class_fee: mainClass?.fee || "",
        }}
      >
        <Form.Item
          name='class_name'
          label={t("Tên lớp")}
          rules={[
            {
              required: true,
              message: "Trường này là bắt buộc!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name='class_teacher' label={t("Giáo viên")}>
          <Select
            showSearch
            filterOption={(inputValue, option) =>
              option.fullname
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            fieldNames={{
              value: "_id",
              label: "fullname",
            }}
            options={teachers}
            labelInValue
            placeholder='Chọn giáo viên'
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name='class_teaching_assistant' label={t("Trợ giảng")}>
          <Select
            showSearch
            filterOption={(inputValue, option) =>
              option.fullname
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            fieldNames={{
              value: "_id",
              label: "fullname",
            }}
            options={assistants}
            labelInValue
            placeholder='Chọn trợ giảng'
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name='class_fee' label={t("Học phí riêng")}>
          <Input />
        </Form.Item>
        {price && (
          <p className='fee-text'>
            Giá bằng chữ:{" "}
            <span>{readNumber(parseInt(price.split(".").join("")))} đồng</span>
          </p>
        )}
        <Form.Item name='class_opening' label={t("classOpening")}>
          <DatePicker style={{ width: "100%" }} format='DD/MM/YYYY' />
        </Form.Item>
        <Form.Item name='class_schedule' label={t("classSchedule")}>
          <Input />
        </Form.Item>
        <Form.Item name='class_hour' label={t("classTime")}>
          <Input />
        </Form.Item>
        <Form.Item name='class_end' label={t("classEnding")}>
          <DatePicker style={{ width: "100%" }} format='DD/MM/YYYY' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSubClassForm;
