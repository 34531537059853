import React from "react";

const data = [
  {
    title: "Đang học",
    color: "#5a3286",
    backgroundColor: "#e6cff2",
  },
  {
    title: "Bảo lưu",
    color: "#11734b",
    backgroundColor: "#d4edbc",
  },
  {
    title: "Đã kết thúc",
    color: "#bfe0f6",
    backgroundColor: "#0a53a8",
  },
  {
    title: "Bỏ học",
    color: "#ffcfc9",
    backgroundColor: "#ff4646",
  },
  {
    title: "Chưa học",
    color: "#ffcfc9",
    backgroundColor: "#ff4646",
  },
  {
    title: "Khách hoàn thành 100% học phí",
    color: "#ffcfc9",
    backgroundColor: "#ff4646",
  },
  {
    title: "Đóng học phí nhưng chưa khai giảng",
    color: "#ffcfc9",
    backgroundColor: "#ff4646",
  },
  {
    title: "Khách combo nhưng chưa KG",
    color: "#ffcfc9",
    backgroundColor: "#ff4646",
  },
  {
    title: "Đã khai giảng",
    color: "#ffc8aa",
    backgroundColor: "#753800",
  },
];

const renderItem = (item) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginBlock: "12px",
    }}
  >
    <div
      style={{
        minWidth: "230px",
      }}
    >
      {item.title}
    </div>
    <div
      style={{
        backgroundColor: item.backgroundColor,
        color: item.color,
        borderRadius: "100px",
        padding: "0 8px",
        height: "20px",
        width: "100px",
        fontSize: "14px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Màu này
    </div>
  </div>
);

const statusItem = (label, value) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginBlock: "12px",
    }}
  >
    <div
      style={{
        minWidth: "230px",
      }}
    >
      {label}
    </div>
    <div>{value}</div>
  </div>
);

export const LevelStudyStatusList = ({ isShowStatusGuide = false }) => {
  return (
    <div
      style={{
        maxHeight: "320px",
        overflowY: "scroll",
      }}
    >
      {isShowStatusGuide ? (
        <>
          {statusItem(
            <img
              src='/phone-call.png'
              alt='phone-call'
              width={20}
              style={{
                marginRight: 5,
              }}
              className='blink'
            />,
            <div>
              Liên hệ tiếp theo <br /> trước 30 phút.
            </div>
          )}
          {statusItem(
            <div
              className='blink-1'
              style={{
                width: "100px",
                textAlign: "center",
              }}
            >
              Nội dung
            </div>,
            <div>
              Ngày khai giảng <br /> trước 3 ngày.
            </div>
          )}
        </>
      ) : null}
      {data.map((item) => (
        <div>{renderItem(item)}</div>
      ))}
    </div>
  );
};
