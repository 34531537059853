import { Descriptions } from "antd";
import dayjs from "dayjs";
import React from "react";
import "../components/PDF/BillPDF.css";
import Watermark from "./Watermark/Watermark";

const BillDetails = React.forwardRef((props, ref) => {
  const { updateItem } = props;

  return (
    <Watermark ref={ref}>
      <Descriptions size='small' style={{ fontSize: "24px" }} bordered>
        <Descriptions.Item label='Mã đơn hàng' span={24}>
          {updateItem?.code}
        </Descriptions.Item>
        <Descriptions.Item label='Ngày lập đơn hàng' span={24}>
          {dayjs().format("DD/MM/YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label='Họ tên học viên' span={24}>
          {updateItem?.name || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='SĐT' span={24}>
          {updateItem?.phone || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Ngày sinh' span={24}>
          {updateItem?.year_of_birthday || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Địa chỉ' span={24}>
          {updateItem?.address || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Cố vấn học thuật' span={24}>
          {updateItem?.cosultant || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Lớp đăng ký' span={24}>
          {updateItem?.joined_class || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Trạng thái học' span={24}>
          {updateItem?.study_status || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Học phí đã thanh toán 1' span={24}>
          {updateItem?.paid_tuition_1 || "-"}
        </Descriptions.Item>
        {parseInt(updateItem?.paid_tuition_2) && (
          <Descriptions.Item label='Học phí đã thanh toán 2' span={24}>
            {updateItem?.paid_tuition_2}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Học phí chưa thanh toán' span={24}>
          {updateItem?.unpaid_tuition || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Khuyến mãi' span={24}>
          {updateItem?.discount || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Thực nhận' span={24}>
          {updateItem?.act_received || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Hình thức thanh toán 1' span={24}>
          {updateItem?.transfer_method_1 || "-"}
        </Descriptions.Item>
        <Descriptions.Item label='Ngày chuyển khoản 1' span={24}>
          {updateItem?.transfer_date_1 &&
            dayjs(updateItem.transfer_date_1).format("DD/MM/YYYY")}
        </Descriptions.Item>
        {updateItem?.transfer_method_2 && (
          <Descriptions.Item label='Hình thức thanh toán 2' span={24}>
            {updateItem.transfer_method_2}
          </Descriptions.Item>
        )}
        {updateItem?.transfer_date_2 && (
          <Descriptions.Item label='Ngày chuyển khoản 2' span={24}>
            {dayjs(updateItem.transfer_date_2).format("DD/MM/YYYY")}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Trạng thái học phí' span={24}>
          {updateItem?.tuition_status || "-"}
        </Descriptions.Item>
      </Descriptions>
    </Watermark>
  );
});

export default BillDetails;
