import { CLASSES } from "../constants";

const getCertV2 = (
  name,
  course,
  open,
  end,
  facility = "Hà Nội",
  width = "1100px"
) => {
  const className = course?.class || "";

  const shouldUseNewCert = [
    CLASSES.l1Online,
    CLASSES.l1Offline,
    CLASSES.l2Online,
    CLASSES.l2Offline,
    CLASSES.l3Online,
    CLASSES.l3Offline,
    CLASSES.l4Online,
    CLASSES.l4Offline,
    CLASSES.laOnline,
    CLASSES.laOffline,
    CLASSES.nhi1,
    CLASSES.nhi2,
    CLASSES.nhi3,
  ].includes(className);

  if (shouldUseNewCert) {
    const getCertBackground = () => {
      switch (className) {
        case CLASSES.l1Online:
        case CLASSES.l1Offline:
          return "cert-l1_2.png";

        case CLASSES.l2Online:
        case CLASSES.l2Offline:
          return "cert-l2_2.png";

        case CLASSES.l3Online:
        case CLASSES.l3Offline:
          return "cert-l3.png";

        case CLASSES.l4Online:
        case CLASSES.l4Offline:
          return "cert-l4.png";

        case CLASSES.laOnline:
        case CLASSES.laOffline:
          return "cert-la.png";

        case CLASSES.nhi1:
          return "cert-l1_1.png";

        case CLASSES.nhi2:
          return "cert-l2_1.png";

        case CLASSES.nhi3:
          return "cert-l3.png";

        default:
          return "cert-bg-2.jpg";
      }
    };

    const classAlias = course?.alias || "";
    const [prefix, suffix] = classAlias.split(":");

    return `
      <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Cert</title>
        <style>
          @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Tomorrow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
    
          .cert-wrapper * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: "Montserrat", sans-serif;
            font-optical-sizing: auto;
            color: black;
          }
    
          .cert-wrapper p {
            margin-bottom: 0 !important;
          }
    
          .cert-wrapper {
            position: relative;
            width: ${width};
            height: 777px;
            margin: 0 auto;
    
            background-image: url("/${getCertBackground()}");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
    
          .cert-name {
            font-size: 55px;
            letter-spacing: -2.5px;
            white-space: nowrap;
            font-style: italic;
    
            position: absolute;
            top: 363px;
            left: 88px;
          }
    
          .cert-info {
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
            position: absolute;
            top: 450px;
            left: 88px;
          }
    
          .cert-time-address {
            font-size: 16px;
            font-weight: 600;
            position: absolute;
            bottom: 34px;
            left: 43%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
    
          .cert-address {
            margin-bottom: 0px;
          }
        </style>
      </head>
      <body>
        <div class="cert-wrapper">
          <div class="cert-name">${name}</div>
          <div class="cert-info">
            Đã hoàn thành khóa học
            <b
              >${classAlias.includes(":") ? `${prefix?.trim()}:` : ""} <br />
              “${classAlias.includes(":") ? suffix?.trim() : prefix?.trim()}”</b
            >
            <br />
            tại THALIC VOICE từ ngày ${open} đến ${end}
          </div>
          <div class="cert-time-address">
            <div class="cert-address">${facility}</div>
            <div class="cert-time">Ngày ${end}</div>
          </div>
        </div>
      </body>
    </html>
      `;
  }

  // cert v1
  return `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Cert</title>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;700&display=swap");
  
        .cert-wrapper * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        .cert-wrapper p {
          margin-bottom: 0 !important;
        }
  
        .cert-wrapper {
          position: relative;
          width: ${width};
          height: 777px;
          margin: 0 auto;
        }

        .cert-content {
          position: absolute;
          top: 250px;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
        }
        .cert-name {
          font-family: "Dancing Script", cursive;
          font-size: 72pt;
          font-weight: 700;
          white-space: nowrap;
          text-align: center;
        }
        .cert-bg img {
          width: 100%;
          height: 100%;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        }
        .cert-description {
          font-family: "Maven Pro", sans-serif;
          font-weight: 400;
          font-size: 18pt;
          text-align: center;
          white-space: nowrap;
          margin-top: -5px;
          line-height: 1.4 !important;
        }
        .cert-description__bold {
          font-weight: 500;
        }
        .cert-time {
          float: right;
          transform: translateX(-155px);
          font-family: "Maven Pro", sans-serif;
          font-weight: 600;
          font-size: 16pt;
          margin-top: 5px;
          white-space: nowrap;
        }
      </style>
    </head>
    <body>
      <div class="cert-wrapper">
        <div class="cert-bg">
          <img src="/cert-bg-2.jpg" alt="Thalic Cert" />
        </div>
        <div class="cert-content">
          <div class="cert-name">${name}</div>
          <div class="cert-description">
            <p>Đã hoàn thành khoá học</p>
            <p>
              <span class="cert-description__bold"
                >"${course?.alias}"</span
              >
              tại THALIC VOICE
            </p>
            <p>từ ngày ${open} đến ngày ${end}.</p>
          </div>
          <div class="cert-time">
            <p>${facility}, ngày ${end}</p>
          </div>
        </div>
      </div>
    </body>
  </html>
  `;
};

export default getCertV2;
