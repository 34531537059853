import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "70vh",
      }}
    >
      <img style={{ width: 150, marginBottom: 20 }} src='/404.svg' alt='404' />
      <p style={{ textAlign: "center" }}>
        <p>Page not found!</p>
        <Link to='/'>Go to Home </Link>
      </p>
    </div>
  );
};

export default NotFoundPage;
