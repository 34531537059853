import {
  DollarCircleOutlined,
  QuestionCircleOutlined,
  RollbackOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Input, List, Row, Spin, Tag, Tooltip } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import { config, userInfo } from "../../utils";
import Ripple from "../Common/Ripple";
import { ListItemDetails } from "../SubClassList/SubClassList";
import "./ClassList.css";
import "../SubClassList/SubClassList.css";

const PAGE_SIZE = 15;

const ClassList = () => {
  const { t } = useTranslation();

  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);

  const {
    data: classListData,
    isLoading,
    isError,
  } = useQuery(["get-class-list"], async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/sub_classes/all-grouped-by-class`,
      config
    );
    return data;
  });

  const {
    data: subClassListData,
    isLoading: isLoadingSubClassList,
    isError: isErrorSubClassList,
  } = useQuery(
    ["get-sub-class-list", page, keyword],
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/sub_classes/sub_class_list?page=${page}&limit=${PAGE_SIZE}&keyword=${keyword}`,
        config
      );
      return data;
    },
    {
      enabled: !!keyword,
    }
  );

  const subClasses = useMemo(
    () => subClassListData?.data?.subClasses,
    [subClassListData?.data?.subClasses]
  );

  const handlePageChange = (page) => {
    setPage(page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  if (isError || isErrorSubClassList) return <NotFoundPage />;

  return (
    <div className='class-list'>
      {isLoading || isLoadingSubClassList ? (
        <Spin />
      ) : (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className='flex items-center'>
              {keyword ? (
                <Button
                  type='primary'
                  icon={<RollbackOutlined />}
                  style={{ marginRight: "8px" }}
                  onClick={() => setKeyword("")}
                >
                  {t("goToClasses")}
                  <Ripple />
                </Button>
              ) : null}
              <Input.Search
                placeholder='Tìm mã lớp học'
                defaultValue={keyword}
                onSearch={(value) => {
                  setPage(1);
                  setKeyword(value);
                }}
                style={{
                  width: 200,
                }}
              />
              {(userInfo?.user.role.includes("admin") ||
                userInfo?.user.role.includes("only_set_class")) && (
                <Link to='/classes/setting'>
                  <Button
                    type='primary'
                    icon={<SettingOutlined />}
                    style={{ marginLeft: "8px" }}
                  >
                    {t("settings2")}
                    <Ripple />
                  </Button>
                </Link>
              )}
            </div>
            {keyword ? (
              <div
                className='sub-class-list'
                style={{
                  paddingInline: 0,
                }}
              >
                <List
                  itemLayout='vertical'
                  size='large'
                  pagination={{
                    onChange: (page) => handlePageChange(page),
                    pageSize: PAGE_SIZE,
                    current: page,
                    total: subClassListData?.pagination?.total || 0,
                  }}
                  dataSource={subClasses}
                  header={
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <h3
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          {subClassListData?.pagination?.total || 0} Lớp học
                        </h3>
                        <Tooltip title='Số lớp học hiện đang có quyền xem. Nếu bạn không tìm thấy lớp học mong muốn, hãy liên hệ với Quản lý lớp học để được thêm vào lớp học đó.'>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </div>
                    </>
                  }
                  renderItem={(item) => (
                    <Link
                      to={`/records?sub_class_id=${item._id}&class_id=${item.class?._id}`}
                    >
                      <List.Item
                        style={{
                          borderRadius: 10,
                        }}
                        key={item._id}
                      >
                        <div className='sub-class-items'>
                          <div className='flex items-center'>
                            <div className=''>
                              <p className='class-name'>{item.class_name}</p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <ListItemDetails
                                  key='list-vertical-star-sc'
                                  label={t("Số học viên")}
                                  value={
                                    <div
                                      style={{
                                        fontWeight: 600,
                                      }}
                                    >
                                      {item.students_count}
                                    </div>
                                  }
                                />
                                <ListItemDetails
                                  key='list-vertical-star-fee'
                                  label={t("Học phí")}
                                  value={`${
                                    item.class_fee ?? item.class?.fee
                                  } đ`}
                                />
                                <ListItemDetails
                                  key='list-vertical-star-open'
                                  label={t("Ngày khai giảng")}
                                  value={
                                    item.class_opening
                                      ? dayjs(item.class_opening).format(
                                          "DD/MM/YYYY"
                                        )
                                      : "-"
                                  }
                                />
                                <ListItemDetails
                                  key='list-vertical-star-end'
                                  label={t("Ngày tổng kết")}
                                  value={
                                    item.class_end
                                      ? dayjs(item.class_end).format(
                                          "DD/MM/YYYY"
                                        )
                                      : "-"
                                  }
                                />
                                <ListItemDetails
                                  key='list-vertical-star-teacher'
                                  label={t("Giáo viên")}
                                  value={item.class_teacher?.fullname || "-"}
                                />
                                <ListItemDetails
                                  key='list-vertical-star-assistant'
                                  icon={UserOutlined}
                                  label={t("Trợ giảng")}
                                  value={
                                    item.class_teaching_assistant?.fullname ||
                                    "-"
                                  }
                                />
                                <ListItemDetails
                                  key='list-vertical-star-lh'
                                  label={t("Lịch học")}
                                  value={item.class_schedule || "-"}
                                />
                                <ListItemDetails
                                  key='list-vertical-star-gh'
                                  label={t("Giờ học")}
                                  value={item.class_hour || "-"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </List.Item>
                      <br />
                    </Link>
                  )}
                />
              </div>
            ) : (
              <Row
                gutter={{
                  xs: 4,
                  sm: 8,
                  md: 16,
                }}
                style={{ marginTop: "20px" }}
              >
                {classListData?.data?.map((classObj) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={classObj._id}>
                    <Link to={`/classes/${classObj._id}`}>
                      <Card
                        className='shine'
                        hoverable
                        cover={
                          <div className='card-cover-container'>
                            <img
                              alt='example'
                              style={{ height: "100%" }}
                              src={classObj.cover || "/logo-new.png"}
                            />
                          </div>
                        }
                      >
                        <Card.Meta
                          title={
                            <div
                              style={{
                                textTransform: "uppercase",
                                marginTop: 10,
                              }}
                            >
                              {classObj.class}
                            </div>
                          }
                          description={
                            <>
                              <Tag color='green'>
                                <UserOutlined
                                  style={{
                                    marginRight: 5,
                                  }}
                                />
                                {classObj.classes_count} Lớp học
                              </Tag>
                              <Tag color='purple'>
                                <DollarCircleOutlined
                                  style={{
                                    marginRight: 5,
                                  }}
                                />
                                {classObj.fee}đ
                              </Tag>
                            </>
                          }
                        />
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            )}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default React.memo(ClassList);
