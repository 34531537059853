const getInvitationLetter = ({
  name,
  course,
  teacher,
  open,
  address,
  width = "540px",
  addressStyle,
}) => {
  return `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Invitation letter</title>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
  
        .invitation-letter-wrapper * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: 'Montserrat', sans-serif;
        }
  
        .invitation-letter-wrapper p {
          margin-bottom: 0 !important;
        }
  
        .invitation-letter-wrapper {
          position: relative;
          width: ${width};
          height: 960px;
          margin: 0 auto;
          overflow: hidden;
        }
  
        .invitation-letter-content {
          position: absolute;
          top: 308px;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
          text-align: center;
          color: #fff;
        }
        .invitation-letter-wrapper .invitation-letter-name {
          font-family: 'Dancing Script', cursive !important;
          font-size: 40px;
          font-weight: 700;
          white-space: nowrap;
        }
        .invitation-letter-course {
          color: #eac96e;
          font-weight: bold;
          font-size: 20px;
          text-transform: uppercase;
          margin: 96px auto 0;
          max-width: ${course?.length > 70 ? "362px" : "340px"};
          line-height: 1.3;
          min-height: 52px;
        }
        .invitation-letter-details {
          font-weight: 400;
          font-size: 15px;
          text-align: left;
          margin-top: 25px;
        }
        .invitation-letter-details .invitation-letter-line {
          max-width: 300px;
        }
        .invitation-letter-details .invitation-letter-line.address {
          max-width: ${addressStyle?.maxWidth}px;
        }
        .invitation-letter-bg img {
          width: 100%;
          height: 100%;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        }
      </style>
    </head>
    <body>
      <div class="invitation-letter-wrapper">
        <div class="invitation-letter-bg">
          <img
            src="/invitation-letter.png"
            alt="Thalic invitation letter"
          />
        </div>
        <div class="invitation-letter-content">
          <div class="invitation-letter-name">${name}</div>
          <div class="invitation-letter-course" style="white-space: pre-line;">${course}</div>
          <div class="invitation-letter-details" style="display: flex; justify-content: center;">
            <div style="display: flex; align-items: flex-start; justify-content: center;">
              <div style="display: flex; flex-direction: column; text-align: right;">
                <b style="margin-right: 10px;">Giáo viên: </b>
                <b style="margin-right: 10px;">Ngày khai giảng: </b>
                <b style="margin-right: 10px;">Địa chỉ: </b>
              </div>
              <div>
                <div class="invitation-letter-line teacher">
                <div>${teacher}</div>
                </div>
                <div class="invitation-letter-line open">
                  <div>${open}</div>
                </div>
                <div class="invitation-letter-line address">
                  <div style="white-space: pre-line;">${address}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </html>  
  `;
};

export default getInvitationLetter;
