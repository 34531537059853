import {
  CalendarOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FolderOpenOutlined,
  QuestionCircleOutlined,
  RollbackOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Input, List, Space, Tooltip, Typography } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { config } from "../../utils/";

import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import Ripple from "../Common/Ripple";
import SkeletonList from "./SkeletonList";
import "./SubClassList.css";

const { Text } = Typography;

const IconText = ({ icon, text }) => (
  <Space className='icon-text'>
    <Text>
      {React.createElement(icon)} {text}
    </Text>
  </Space>
);

export const ListItemDetails = ({ icon, label, value }) => (
  <Space
    direction='horizontal'
    style={{
      display: "flex",
    }}
  >
    <div
      style={{
        width: "130px",
        color: "rgb(107, 111, 121)",
      }}
    >
      {/* {icon && React.createElement(icon)} */}
      {label}:
    </div>
    <div>{value}</div>
  </Space>
);

const PAGE_SIZE = 15;

const SubClassList = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const pathSplit = location.pathname.toString().split("/");
  const classId = pathSplit[pathSplit.length - 1];

  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");

  const {
    data: subClassListData,
    isLoading,
    isError,
  } = useQuery(
    ["get-sub-class-list", classId, page, keyword],
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/sub_classes/sub_class_list?primary_class_id=${classId}&page=${page}&limit=${PAGE_SIZE}&keyword=${keyword}`,
        config
      );
      return data;
    },
    {
      enabled: !!classId,
    }
  );

  const primaryClass = useMemo(
    () => subClassListData?.data?.primaryClass,
    [subClassListData?.data?.primaryClass]
  );

  const subClasses = useMemo(
    () => subClassListData?.data?.subClasses,
    [subClassListData?.data?.subClasses]
  );

  const handlePageChange = (page) => {
    setPage(page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  if (isError) return <NotFoundPage />;

  return (
    <div className='sub-class-list'>
      {!isLoading && primaryClass && subClasses ? (
        <>
          <Helmet>
            <title>{primaryClass.class} | THALIC CRM</title>
            <meta
              property='og:title'
              content={`${primaryClass?.class} | THALIC CRM`}
            />
          </Helmet>
          <AnimatePresence>
            <motion.div
              initial={{
                y: 20,
                opacity: 0,
              }}
              animate={{ y: 0, opacity: 1 }}
              exit={{
                y: 20,
                opacity: 0,
                position: "absolute",
              }}
              transition={{ duration: 0.5 }}
            >
              <List
                itemLayout='vertical'
                size='large'
                pagination={{
                  onChange: (page) => handlePageChange(page),
                  pageSize: PAGE_SIZE,
                  current: page,
                  total: subClassListData?.pagination?.total || 0,
                }}
                dataSource={subClasses}
                header={
                  <>
                    <div className='sub-class-header'>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <Link to={`/classes`}>
                          <Button
                            style={{ marginBottom: "20px" }}
                            type='primary'
                            icon={<RollbackOutlined />}
                          >
                            {t("goToClasses")}
                            <Ripple />
                          </Button>
                        </Link>
                        <Input.Search
                          placeholder='Tìm mã lớp học'
                          defaultValue={keyword}
                          onSearch={(value) => {
                            setPage(1);
                            setKeyword(value);
                          }}
                          style={{
                            width: 200,
                            marginBottom: "20px",
                            marginLeft: 20,
                          }}
                        />
                      </div>

                      <div>
                        {t("classListBelongsTo")} <b>{primaryClass?.class}</b>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <h3
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        {subClassListData?.pagination?.total || 0} Lớp học
                      </h3>
                      <Tooltip title='Số lớp học hiện đang có quyền xem. Nếu bạn không tìm thấy lớp học mong muốn, hãy liên hệ với Quản lý lớp học để được thêm vào lớp học đó.'>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  </>
                }
                renderItem={(item) => (
                  <Link
                    to={`/records?sub_class_id=${item._id}&class_id=${primaryClass?._id}`}
                  >
                    <List.Item
                      style={{
                        borderRadius: 10,
                      }}
                      key={item._id}
                    >
                      <div className='sub-class-items'>
                        <div className='flex items-center'>
                          <div className=''>
                            <p className='class-name'>{item.class_name}</p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <ListItemDetails
                                key='list-vertical-star-sc'
                                icon={TeamOutlined}
                                label={t("Số học viên")}
                                value={
                                  <div
                                    style={{
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.students_count}
                                  </div>
                                }
                              />
                              <ListItemDetails
                                key='list-vertical-star-fee'
                                icon={DollarOutlined}
                                label={t("Học phí")}
                                value={`${
                                  item.class_fee ?? primaryClass?.fee
                                } đ`}
                              />
                              <ListItemDetails
                                key='list-vertical-star-open'
                                icon={FolderOpenOutlined}
                                label={t("Ngày khai giảng")}
                                value={
                                  item.class_opening
                                    ? dayjs(item.class_opening).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"
                                }
                              />
                              <ListItemDetails
                                key='list-vertical-star-end'
                                icon={FileDoneOutlined}
                                label={t("Ngày tổng kết")}
                                value={
                                  item.class_end
                                    ? dayjs(item.class_end).format("DD/MM/YYYY")
                                    : "-"
                                }
                              />
                              <ListItemDetails
                                key='list-vertical-star-teacher'
                                icon={UserOutlined}
                                label={t("Giáo viên")}
                                value={item.class_teacher?.fullname || "-"}
                              />
                              <ListItemDetails
                                key='list-vertical-star-assistant'
                                icon={UserOutlined}
                                label={t("Trợ giảng")}
                                value={
                                  item.class_teaching_assistant?.fullname || "-"
                                }
                              />
                              <ListItemDetails
                                key='list-vertical-star-lh'
                                icon={CalendarOutlined}
                                label={t("Lịch học")}
                                value={item.class_schedule || "-"}
                              />
                              <ListItemDetails
                                key='list-vertical-star-gh'
                                icon={CalendarOutlined}
                                label={t("Giờ học")}
                                value={item.class_hour || "-"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                    <br />
                  </Link>
                )}
              />
            </motion.div>
          </AnimatePresence>
        </>
      ) : (
        <SkeletonList />
      )}
    </div>
  );
};

export default SubClassList;
