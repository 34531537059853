import { Button, Form, Input, Modal, Select, Spin } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../../utils";
import studyStatus from "../../utils/studyStatus";

const { Option } = Select;
const { TextArea } = Input;

const UpdateForm = ({ visible, onUpdate, onCancel, updateItem, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);

  const fetchItem = useCallback(async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/records/${updateItem._id}`,
      config
    );

    setData(data.data);
  }, [updateItem]);

  useEffect(() => {
    if (updateItem) {
      fetchItem();
    }
    return () => {
      setData(null);
      form.resetFields();
    };
  }, [updateItem, fetchItem, form]);

  return (
    <Modal
      open={visible}
      title={t("editRecordModal")}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t("cancel")}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onUpdate(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          {t("ok")}
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical' name='form_in_modal'>
        {data ? (
          <>
            <div className='inline-input-3'>
              <Form.Item name='teacher_score' label={t("teacherScore")}>
                <Input defaultValue={data.teacher_score || ""} />
              </Form.Item>
              <Form.Item
                name='teaching_assistant_score'
                label={t("assistantScore")}
              >
                <Input defaultValue={data.teaching_assistant_score || ""} />
              </Form.Item>
              <Form.Item name='tvv_score' label={t("consultantScore")}>
                <Input defaultValue={data.tvv_score || ""} />
              </Form.Item>
            </div>

            <div id='popup-study_status-dt'>
              <Form.Item name='study_status' label={t("studyStatus")}>
                <Select
                  defaultValue={data.study_status}
                  getPopupContainer={() =>
                    document.getElementById("popup-study_status-dt")
                  }
                >
                  {studyStatus.map((status) => (
                    <Option value={status}>{status}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div id='popup-note_b4-dt'>
              <Form.Item name='note_b4' label={t("b4Note")}>
                <TextArea showCount defaultValue={data.note_b4} />
              </Form.Item>
            </div>
            <div id='popup-note_tk-dt'>
              <Form.Item name='note_tk' label={t("tkNote")}>
                <TextArea showCount defaultValue={data.note_tk} />
              </Form.Item>
            </div>
          </>
        ) : (
          <Spin />
        )}
      </Form>
    </Modal>
  );
};

export default React.memo(UpdateForm);
